import React from "react"
// import { graphql } from "gatsby"
import {
  Layout,
  Hero,
  About,
  Services,
  Membership
} from "../components"
// import { Services } from "../components/home/Services"
// import SEO from "../components/seo"
const HomePage = ({ data }) => {
  // const {allAirtable: {nodes:services}} = data
  return (
    <Layout>
      <Hero />
      <About />

      <Services />
      {/* <Hours /> */}

      {/* <Membership /> */}
    </Layout>
  )
}

export default HomePage

// ...GatsbyImageSharpFluid
// ...GatsbyImageSharpFixed
